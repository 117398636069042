
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































































































































@import '@shared/styles/voo-chrome.scss';

.chrome-storybook {
  min-height: 50vh;
  padding-top: 15rem;
  padding-bottom: 15rem;
  background-color: #ccc;
}

.chrome-storybook__togglers {
  margin-bottom: $spacing * 2;

  button {
    margin-left: 2rem;
  }
}

.chrome-storybook-tips {
  position: relative;
  margin: $spacing * 2 0;
  padding: 0 2rem;
  background-color: white;
  border: 1px solid $c-pink-medium;
  border-radius: 10px;

  h4 {
    margin-bottom: $spacing * 0.5;
  }

  p + h4 {
    margin-top: $spacing * 2;
  }
}

.chrome-storybook .is-on {
  background-color: dimgray;
  color: white;
}
